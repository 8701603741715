import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    redirect: "/login",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Login/LoginView.vue"),
  },
  {
    path: "/login",
    name: "login", //para usar el router link sin modificar la ruta web
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Login/LoginView.vue"),
  },
  {
    path: "/munienlinea",
    name: "munienlinea",
    component: () => import("../views/HomeVecino/HomeVecinoView.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/presentadas",
    name: "presentadas",
    component: () => import("@/views/HistorialTramites/TramitesView.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/turneroweb",
    name: "turneroweb",
    component: () => import("../views/Turnero/TurneroWebView.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/auth/reset-password/:token",
    name: "reset-password",
    component: () => import("@/views/ResetPassword/ResetView.vue"),
    meta: { requiresAuth: false },
  },
  // {
  //   path: "/pago/:idTramite",
  //   name: "pago",
  //   component: () => import("@/views/PagosTramites/PagoView.vue"),
  //   meta: { requiresAuth: true },
  // },
  {
    path: "/pago-resultado",
    name: "pago-resultado",
    component: () => import("@/views/PagoVista/pagoResultadoView.vue"),
  },
  {
    //PORTAL TRIBUTARIA
    path: "/portal-tributario/:sectorId",
    name: "portal-tributario",
    component: () => import("../views/ProcesoTramite/PortalTributarioView.vue"),
    meta: { requiresAuth: true },
  },
  {
    //PORTAL TRIBUTARIA
    path: "/portal-tributario/pagar-tasas",
    name: "portal-tributario-tasas",
    component: () => import("../views/ProcesoTramite/PagarTasasView.vue"),
    meta: { requiresAuth: true },
  },
  {
    //PORTAL TRIBUTARIA TRANSITO
    path: "/portal-transito/:sectorId/:nombreSector/:sectorIdDos/:nombreSectorDos",
    name: "portal-transito",
    component: () => import("../views/ProcesoTramite/PortalTransitoView.vue"),
    meta: { requiresAuth: true },
  },
  {
    //PORTAL OBRAS y CATASTRO
    path: "/portal-obras/:sectorId/:nombreSector/:sectorIdDos/:nombreSectorDos",
    name: "portal-obras",
    component: () => import("../views/ProcesoTramite/PortalObrasView.vue"),
    meta: { requiresAuth: true },
  },
  {
    //PARA VISTA DE SUBACTEGORIA
    path: "/sector/:sectorTitle/:sectorId/:tramiteId",
    name: "subCategoria",
    component: () => import("../views/ProcesoTramite/SubCategoriaView.vue"),
    meta: { requiresAuth: true },
  },

  {
    //SectorTitle se pasa unicamente como prop para header de la vista
    path: "/sector/:sectorId",
    name: "sector",
    component: () => import("../views/ProcesoTramite/SectorView.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/cuestionario/:formularioTitle/:tramiteId",
    name: "formulario",
    component: () => import("@/views/ProcesoTramite/FormularioView.vue"),
    meta: { requiresAuth: true },
  },
  //requisitos
  {
    path: "/requisitos/:formularioTitle/:tramiteId",
    name: "requisitos",
    component: () => import("@/views/Requisitos/RequisitosView.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/notificaciones",
    name: "notificaciones",
    component: () => import("@/views/Notificaciones/NotificacionesView.vue"),
    meta: { requiresAuth: true },
  },

  {
    path: "/micuenta",
    name: "micuenta",
    component: () => import("@/views/PerfilVecino/CuentaView.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/error",
    name: "error",
    component: () => import("@/views/Error404/ErrorView.vue"),
    // meta: { requiresAuth: true },
  },

  //VISTAS MUNICIPALES
  {
    path: "/muni-agentes",
    name: "muni-agentes",
    component: () =>
      import("@/views/Municipal/HomeMunicipal/HomeMunicipalView.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/tareas",
    name: "tareas",
    component: () => import("@/views/Municipal/Tareas/TareasView.vue"),
  },
  {
    path: "/turnero",
    name: "turnero",
    component: () => import("@/views/Municipal/Turnos/TurnosView.vue"),
  },
  {
    path: "/tabla-vecinos",
    name: "tabla-vecinos",
    component: () => import("@/views/Municipal/Vecinos/VecinoView.vue"),
  },
  {
    path: "/noticias",
    name: "noticias",
    component: () => import("@/views/Municipal/Noticias/NoticiasView.vue"),
  },
  {
    path: "/crear-noticia",
    name: "crear-noticia",
    component: () => import("@/views/Municipal/Noticias/CrearNoticiaView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  let actual = localStorage.getItem("token");

  if (to.matched.some((route) => route.meta.requiresAuth)) {
    if (actual != null) {
      next();
    } else {
      next("/login");
    }
  } else {
    next();
  }
});

export default router;
