import axios from "axios";
import { BASE_URL } from "@/env";
let token = "";
let dispatchTokenUser = () => {
  this.$store.dispatch("mockTokenUserAction", token);
};
export default function setToken() {
  let refresh = localStorage.getItem("refreshToken"); ///se toma el token refresh
  const apiClient = axios.create({
    baseURL: BASE_URL,
    withCredentials: false,
    headers: {
      "auth-header": localStorage.getItem("token"),
    },
  });
  apiClient
    .post("/refresh-token", {
      refreshToken: refresh,
    })
    .then((response) => {
      localStorage.setItem("token", response.data.Token);
      token = response.data.Token;
      dispatchTokenUser();
    })
    .catch((error) => {
      console.log(error.response, "soy el error del refreshToken");
    });
}
