import { createStore } from "vuex";

export default createStore({
  state: {
    loggedIn: false,
    token: "",
    procedure: [],
    user: "",
    CidiCookie: null,
    representante: null,
    tramiteStore: null,
    opcionTramiteStore: null,
    subOpcionTramiteStore: null,
  },
  getters: {
    getProfile(state) {
      return state.firstname;
    },
  },
  mutations: {
    loggedIn(state, asd) {
      state.user = asd;
    },
    setCidi(state, asd) {
      state.CidiCookie = asd;
    },
    saveProcedure(state, asd) {
      //let obj = JSON.parse(asd);
      state.procedure.push(asd);
    },
    cleanStore(state) {
      state.procedure = [];
    },

    representante(state, asd) {
      state.representante = asd;
    },
    login(state) {
      state.loggedIn = true;
    },
    outLogin(state) {
      state.loggedIn = false;
    },
    setTramiteStore(state, obj) {
      state.tramiteStore = obj;
    },
    setOpcionTramiteStore(state, obj) {
      state.opcionTramiteStore = obj;
    },
    setSubOpcionTramiteStore(state, obj) {
      state.subOpcionTramiteStore = obj;
    },
    setTokenUser(state, token) {
      state.token = token;
    },
    clearTramite(state) {
      (state.opcionTramiteStore = null), (state.tramiteStore = null);
      state.subOpcionTramiteStore = null;
    },
  },
  actions: {
    mockLoginAction(context, asd) {
      context.commit("loggedIn", asd);
    },
    mockCidiAction(context, asd) {
      context.commit("setCidi", asd);
    },
    mockRepresentanteAction(context, asd) {
      context.commit("representante", asd);
    },
    saveP(context, asd) {
      context.commit("saveProcedure", asd);
    },
    mockPaseAction(context) {
      context.commit("login");
    },
    mockOutAction(context) {
      context.commit("outLogin");
    },
    cleanAction(context) {
      context.commit("cleanStore");
    },
    setTramiteAction(context, obj) {
      context.commit("setTramiteStore", obj);
    },
    setOpcionTramiteAction(context, obj) {
      context.commit("setOpcionTramiteStore", obj);
    },
    setSubOpcionTramiteAction(context, obj) {
      context.commit("setSubOpcionTramiteStore", obj);
    },
    mockTokenUserAction(context, token) {
      context.commit("setTokenUser", token);
    },
    clearTramiteAction(context) {
      context.commit("clearTramite");
    },
  },
  modules: {},
});
