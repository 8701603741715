<template>
  <NavTopVue
    v-if="
      this.role != 'MUNI_ROLE' &&
      this.role != 'SUPERVISOR_ROLE' &&
      this.setPermission
    "
  />
  <NavTopMunicipalComponent
    v-if="
      this.role === 'MUNI_ROLE' ||
      (this.role === 'SUPERVISOR_ROLE' && this.setPermission)
    "
  />
  <NavPanelComponent
    v-if="
      this.role === 'MUNI_ROLE' ||
      (this.role === 'SUPERVISOR_ROLE' && this.setPermission)
    "
  />
  <div class="main-container">
    <NavComponent
      v-if="
        role != 'MUNI_ROLE' &&
        this.role != 'SUPERVISOR_ROLE' &&
        this.setPermission
      "
    />
    <FechaComponent
      v-if="
        this.role != 'MUNI_ROLE' &&
        this.role != 'SUPERVISOR_ROLE' &&
        this.setPermission
      "
    />
    <router-view />

    <!-- <ReporteErrorComponent /> -->
    <FooterComponentVue
      v-if="
        this.role != 'MUNI_ROLE' &&
        this.role != 'SUPERVISOR_ROLE' &&
        this.setPermission
      "
    />
  </div>
</template>

<script>
import NavComponent from "@/components/MuniEnLinea/NavComponent.vue";
import NavTopVue from "./components/MuniEnLinea/NavTop.vue";
import FooterComponentVue from "@/components/Footer/FooterComponent.vue";
import FechaComponent from "@/components/Fecha/FechaComponent.vue";
// import ReporteErrorComponent from "./components/ReporteError/ReporteErrorComponent.vue";
//MUNICIPALES
import NavTopMunicipalComponent from "./components/Municipal/Nav/NavTopMunicipalComponent.vue";
import NavPanelComponent from "./components/Municipal/Nav/NavPanelComponent.vue";
export default {
  data() {
    return {
      role: "",
      cuil: "",
      ancho: "",
      margin: "",
      posision: "",
      derecha: "",
      asd: this.$route.fullPath,
      notLogin: false,
    };
  },
  created() {
    this.role = localStorage.getItem("role");
  },
  watch: {
    $route() {
      this.role = localStorage.getItem("role");
    },
  },
  computed: {
    setPermission() {
      if (this.$store.state.loggedIn === true) {
        return true;
      } else {
        return false;
      }
    },
    setClass() {
      if (this.asd === "/login" || this.asd !== "/") {
        return true;
      } else {
        return false;
      }
    },
  },
  components: {
    NavComponent,
    NavTopVue,
    FooterComponentVue,
    FechaComponent,
    // ReporteErrorComponent,
    NavTopMunicipalComponent,
    NavPanelComponent,
  },
};
</script>

<style>
* {
  box-sizing: border-box;
  /* margin: 0;
  padding: 0; */
}

#app {
  /* user-select: none; */
  /* -webkit-font-smoothing: antialiased; */
  input {
    outline: none;
  }
  font-family: "Montserrat", sans-serif;
  padding: 0;
  margin: 0;

  min-height: 100vh;
  overflow: hidden;
  --dp-scroll-bar-color: #00c1a6;
  --dp-primary-color: #00c1a6;
  /* */
  /* --dp-button-height: 24px; */
  /* --dp-input-icon-padding: 24px; */
  background: #f5f5f5;
  --bordecard: #9b9a9a37;
  --verdePrincipal: #00c3a8;
  --amarilloAverde: linear-gradient(
    270deg,
    #00c3a8 0.31%,
    #ffb900 75.82%,
    #ff2745 163.37%
  );
  --bordeDegrade: linear-gradient(
    to right,
    #ff6928,
    #ff8219,
    #ff9a08,
    #ecab00,
    #d7b909,
    #c2c627,
    #96ca4b,
    #6ccb6d,
    #3fc88d,
    #00c3a8
  );
  --verdeTablas: #00c3a833;
  --verdeDegrade: linear-gradient(90deg, #00c3a8 4.26%, #ffb900 126.04%);
  --verdeDegradeNav: linear-gradient(270deg, #72c057 0%, #00c3a8 100%);

  --verdeDegradeTres: linear-gradient(270deg, #00c3a8 0.07%, #72c057 251.05%);
  --rojoDegrade: linear-gradient(90deg, #ffcc03 -6.36%, #e52320 100%);
  --texto: #3e3e3e;
  --gris: #9b9a9a7a;
  --grisClaro: #f5f5f5;
}
@media (max-width: 800px) {
  #app {
    --dp-input-padding: 0px;
  }
}
/* body {
  overflow: scroll;
} */

/* ---------------------------- */
</style>

<style scoped>
.main-container {
  width: 100%;
  /* min-height: 100vh; */
  position: relative;
}
/* 
.botonError {
  position: fixed;
  right: 0px;
  bottom: 187px;
} */

@media (max-width: 800px) {
  #app {
    width: 100vw;
  }
  /* .claseB {
    width: 100vw;
  }
  .footer {
    width: 100%;
    bottom: 0;
  } */
}
</style>
