<template>
  <footer class="general">
    <div class="linea"></div>
    <div class="contenedor">
      <div class="grupoRedes">
        <a href="http://wa.me/3573413371" target="_blank">
          <img class="redes" src="@/assets/images/Wpp.svg" alt="" />
        </a>
        <a
          href="https://www.instagram.com/municipalidad_de_luque/"
          target="_blank"
        >
          <img src="@/assets/images/Instagram.svg" alt="" />
        </a>
        <a
          href="https://www.facebook.com/MunicipalidaddeLuque/"
          target="_blank"
        >
          <img src="@/assets/images/Faceboock.svg" alt="" />
        </a>
      </div>
      <h6>
        Municipalidad de Luque | 9 de Julio 806, Luque, Córdoba | CP X5967 |
        TEL.:03573 48-0113
      </h6>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterComponent",
};
</script>

<style scoped>
.general {
  position: absolute;
  /* position: fixed; */
  bottom: 0;
  right: 0;
  height: 108px;
  background-color: #f5f5f5;
  width: 100%;
  padding-left: 239px;
  text-align: right;
  justify-content: center;
  /* z-index: -1; */
}
.contenedor {
  display: flex;
  flex-direction: row;
  /* gap: 157px; */
  justify-content: space-between;
  align-items: center;
  padding-right: 90px;
}
.linea {
  height: 4px;
  width: 100%;
  background-image: var(--rojoDegrade);
  margin-bottom: 32px;
}
h6 {
  font-size: 16px;
  font-weight: 400;
  color: #858585;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.grupoRedes {
  margin-left: 22px;
  width: 132px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.redes {
  background: var(--rojoDegrade);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  font-size: 35px;
}
@media (max-width: 1200px) {
  .general {
    padding-left: 0px;
  }
  .redes {
    font-size: 35px;
  }
  .grupoRedes {
    width: 12vw;
  }
  h6 {
    font-size: 15px;
    font-weight: 200;
  }
}
@media (max-width: 1000px) {
  .redes {
    font-size: 25px;
  }
  .grupoRedes {
    width: 15%;
  }
  h6 {
    font-size: 12px;
    font-weight: 200;
  }
}
@media (max-width: 800px) {
  .general {
    /* position: relative; */
    width: 100%;
    /* z-index: 20; */
    /* width: 100vw; */
    margin-left: 0;
  }
  h6 {
    font-size: 12px;
    font-weight: 200;
  }
  .redes {
    font-size: 25px;
  }
  .grupoRedes {
    width: 15vw;
  }
}
@media (max-width: 770px) {
  .general {
    /* position: relative; */
    text-align: center;
    width: 100%;
    height: 108px;
    /* z-index: 20; */
    /* width: 100vw; */
    /* margin-left: 0; */
    padding-bottom: 50px;
  }
  .contenedor {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    gap: 5px;
    width: 100%;
    padding: 0;
    padding-bottom: 50px;
  }
  h6 {
    font-size: 11.2px;
    font-style: normal;
    font-weight: 400;
  }
  .grupoRedes {
    /* margin-left: 22px; */
    width: 132px;
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  img {
    height: 28px;
    width: 28px;
  }
}
</style>
