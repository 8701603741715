<template>
  <!-- Sidebar -->
  <div class="nav-container">
    <!--el los usuarios que tienen representados ,el linck de inicio no se muestra hasta que seleccionar a quien representar-->
    <div class="tusGestiones"></div>

    <div class="navUser">
      <router-link
        v-show="setPermission"
        :to="`/munienlinea`"
        class="bn3"
        style="background: var(--rojoDegrade)"
      >
        <h5>Inicio</h5>
      </router-link>
      <router-link v-show="setPermission" :to="`/turneroweb`" class="bn3">
        <h5>Tus turnos</h5>
      </router-link>

      <router-link v-show="setPermission" :to="`/presentadas`" class="bn3">
        <h5>Tus gestiones</h5>
      </router-link>
      <router-link
        v-show="setPermission"
        :to="`/notificaciones`"
        class="bn3"
        style="background: var(--rojoDegrade)"
      >
        <h5>Comunicaciones</h5>
      </router-link>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { BASE_URL } from "@/env";

export default {
  name: "NavComponent",
  data() {
    return {
      ruta: this.$router.currentRoute.value.fullPath,
      report: "",
      sendR: false,
    };
  },
  created() {},
  watch: {},
  computed: {
    setPermission() {
      if (this.$store.state.loggedIn === true) {
        return true;
      } else {
        return false;
      }
    },
  },

  methods: {
    sendReport() {
      // console.log(this.report, "soy el reporte");
      const apiClient = axios.create({
        baseURL: BASE_URL,
        withCredentials: false,
        headers: {
          "auth-header": localStorage.getItem("token"),
        },
      });
      apiClient
        .post("/feedback/bug-report/user", {
          report: this.report,
        })
        .then((response) => {
          // console.log(response);
          // this.message = response.data.message;
          if (response.status === 201) {
            this.sendR = true;
          }
          this.report = "";
        })
        .catch((error) => {
          this.msj = error.response.data.message;

          console.log(error.data);
        });
    },
  },
};
</script>

<style scoped>
/* CSS NUEVO */
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0px;
  color: #fff;
}
.nav-container {
  color: var(--text-color);
  position: fixed;
  margin-top: 102px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 252px;
  height: 100%;
  background: #fefefe;
  background-image: url("./../../assets/images/FondoNavLuque.svg");
  background-repeat: repeat;
  background-position: inherit;
  z-index: 2;
}
.tusGestiones {
  position: relative;
  top: 99px;
  margin: auto;
  margin-bottom: 0;
  margin-top: 0;
  height: 92px;
  width: 92px;
  background-image: url("./../../assets/images/LogoEco.svg");
  background-repeat: no-repeat;
  background-position: center;
}

.navUser {
  display: flex;
  flex-direction: column;
  position: relative;
  margin: auto;
  margin-top: 162px;
  gap: 15px;
  align-items: center;
}
.bn3 {
  height: 37.4px;
  width: 188px;
  text-align: center;
  background-image: var(--verdeDegradeNav);
  border-radius: 20px;
  transition: all 0.2s;
  border-style: none;
  color: white;
  padding: 8px;
}
a {
  text-decoration: none;
}
h5 {
  color: white;
  font-weight: 700;
  font-size: 16.6px;
  font-style: normal;
  line-height: normal;
}

/* ----------------------------------------------- */

@media (max-width: 1200px) {
  h5 {
    font-size: 15px;
  }
  .nav-container {
    width: 21%;
  }
}

@media (max-width: 900px) {
  .nav-container {
    display: none;
  }
}
</style>
